<template>
  <div class="floating-container">
    <a class="floating-button" href="#" target="_blank" @click="openGlossary">
      <img src="@/assets/img/glossary-icon.png" alt="Glossary" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'FabButton',
  methods: {
    openGlossary() {
      const routeData = this.$router.resolve({ name: 'Glossary'})
      window.open(routeData.href, '_blank')
    }
  }
};
</script>

<style lang="scss">
.floating-container {
  z-index: 99999;
  width: 70px;
  height: 70px;
  background-color: $primary;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  box-shadow: 0 5px 19px rgba(46, 50, 60, 0.18);
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
  }
  &:hover {
    background-color: $secondary;
  }
}
</style>
