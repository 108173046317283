<template>
  <div class="row partners-row">
    <!-- Carousel XL -->
    <div class="slide-xl">
      <el-carousel height="150px" :autoplay="true" :interval="2500" indicator-position="none" arrow="never">
        <el-carousel-item>
          <div class="logo-container">
            <img
              v-for="logo in slide1Xl"
              :key="logo.src"
              class="slide-logo"
              :src="logo.src"
              height="80"
              :alt="logo.alt"
            /></div
        ></el-carousel-item>
        <el-carousel-item>
          <div class="logo-container">
            <img
              v-for="logo in slide2Xl"
              :key="logo.src"
              class="slide-logo"
              :src="logo.src"
              height="80"
              :alt="logo.alt"
            /></div
        ></el-carousel-item>
        <el-carousel-item>
          <div class="logo-container">
            <img
              v-for="logo in slide3Xl"
              :key="logo.src"
              class="slide-logo"
              :src="logo.src"
              height="80"
              :alt="logo.alt"
            /></div
        ></el-carousel-item>
      </el-carousel>
    </div>
    <!-- Carousel MD -->
    <div class="slide-md">
      <el-carousel height="150px" :autoplay="true" :interval="2500" indicator-position="none" arrow="never">
        <el-carousel-item>
          <div class="logo-container">
            <img
              v-for="logo in slide1Md"
              :key="logo.src"
              class="slide-logo"
              :src="logo.src"
              height="80"
              :alt="logo.alt"
            /></div
        ></el-carousel-item>
        <el-carousel-item>
          <div class="logo-container">
            <img
              v-for="logo in slide2Md"
              :key="logo.src"
              class="slide-logo"
              :src="logo.src"
              height="80"
              :alt="logo.alt"
            /></div
        ></el-carousel-item>
        <el-carousel-item>
          <div class="logo-container">
            <img
              v-for="logo in slide3Md"
              :key="logo.src"
              class="slide-logo"
              :src="logo.src"
              height="80"
              :alt="logo.alt"
            /></div
        ></el-carousel-item>
        <el-carousel-item>
          <div class="logo-container">
            <img
              v-for="logo in slide4Md"
              :key="logo.src"
              class="slide-logo"
              :src="logo.src"
              height="80"
              :alt="logo.alt"
            /></div
        ></el-carousel-item>
      </el-carousel>
    </div>
    <!-- Carousel SM -->
    <div class="slide-sm">
      <el-carousel height="150px" :autoplay="true" :interval="2500" indicator-position="none" arrow="never"	>
        <el-carousel-item v-for="logo in partners" :key="logo.src">
          <div class="logo-container">
            <img
              class="slide-logo"
              :src="logo.src"
              height="80"
              :alt="logo.alt"
            /></div
        ></el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import partners from '@/utils/data/partners';

export default {
  name: 'LogoSlider',
  data() {
    return {
      partners,
    };
  },
  computed: {
    slide1Xl() {
      return this.partners.slice(0, 4);
    },
    slide2Xl() {
      return this.partners.slice(4, 8);
    },
    slide3Xl() {
      return this.partners.slice(8, 12);
    },
    slide1Md() {
      return this.partners.slice(0, 3);
    },
    slide2Md() {
      return this.partners.slice(3, 6);
    },
    slide3Md() {
      return this.partners.slice(6, 8);
    },
    slide4Md() {
      return this.partners.slice(8, 12);
    },
  },
};
</script>

<style lang="scss">
.partners-row {
  padding-top: 3rem;
}

.logo-container {
  display: flex;
  justify-content: space-between;
}

.slide-sm, .slide-md {
  display: none;
}

@include bp-down(lg) {
  .slide-xl {
    display: none;
  }
  .slide-md {
    display: block;
  }
}

@include bp-down(md) {
  .slide-xl,
  .slide-md {
    display: none;
  }
  .slide-sm {
    display: block;
  }
  .logo-container {
  display: flex;
  justify-content: center;
}
}
</style>
