<template>
  <Navbar />
  <router-view />
  <a
    class="interreg-logo"
    href="https://www.cedaci.org/"
    target="_blank"
    aria-label="Read more about CEDaCI"
  >
    <img
      src="@/assets/img/interreg-cedaci-logo.png"
      alt="Interreg CEDACI logo"
    />
  </a>
  <fab-button />
</template>

<script>
import Navbar from '@/components/nav/Navbar';
import FabButton from '@/components/nav/Fab';
export default {
  name: 'App',
  components: {
    Navbar,
    FabButton,
  },
  created() {
    this.$store.dispatch('isLoggedIn');
  },
};
</script>

<style lang="scss">
.interreg-logo {
  z-index: 99999;
  width: 180px;
  height: 100px;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
  display: flex;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  box-shadow: 0 5px 19px rgba(46, 50, 60, 0.18);
  img {
    width: 100%;
    height: auto;
  }
}

@include bp-down(md) {
  .interreg-logo {
    display: none;
  }
}
</style>
