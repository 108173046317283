<template>
  <div class="definition-box break-words">
    <p class="text-bold text-primary">{{ definition.acronym }}</p>
    <p class="fs-14">{{ definition.text }}</p>
  </div>
</template>

<script>
// acronym/text/info
export default {
  name: 'AppDefinition',
  props: {
    definition: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.definition-box {
  p {
    margin-bottom: 5px;
  }
}
</style>
