import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/styles';
import GlobalComponents from './utils/globals';
import VueApexCharts from 'vue3-apexcharts';
import ElementPlus from 'element-plus';
import interceptors from './utils/services/interceptors';

interceptors();

const app = createApp(App);
app.use(VueAxios, axios);
app.use(store);
app.use(router);
app.use(GlobalComponents);
app.use(VueApexCharts);
app.use(ElementPlus);
app.mount('#app');
