import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/compass',
    name: 'Compass',
    component: () =>
      import(/* webpackChunkName: "compass" */ '../views/Compass.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/glossary',
    name: 'Glossary',
    component: () =>
      import(/* webpackChunkName: "glossary" */ '../views/Glossary.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'
      ),
  },
  {
    path: '/my-account',
    name: 'Account',
    component: () =>
      import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/evaluate',
    name: 'Evaluate',
    component: () =>
      import(/* webpackChunkName: "evaluate" */ '../views/Evaluate.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/compare',
    name: 'Compare',
    component: () =>
      import(/* webpackChunkName: "compare" */ '../views/Compare.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/endoflife',
    name: 'EndOfLife',
    component: () =>
      import(/* webpackChunkName: "endoflife" */ '../views/EndOfLife.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/compare-endoflife',
    name: 'CompareEoL',
    component: () =>
      import(
        /* webpackChunkName: "endoflife" */ '../components/endoflife/CompareEoL.vue'
      ),
    meta: {
      requireAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requireAuth)) {
    next();
    return;
  }
  if (store.state.auth.userLoggedIn) {
    next();
  } else {
    next({ name: 'Home' });
  }
});

export default router;
