<template>
  <span class="info-btn" @click="showModal = true"
    ><i class="bi bi-info-circle"></i>
  </span>
  <el-dialog v-model="showModal" :title="title" custom-class="info-modal">
    <slot />
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showModal = false"
          >Continue</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'AppInfo',
  props: ['title'],
  data() {
    return {
      showModal: true,
    };
  },
  // async created() {
  //   const firstTimer = await this.$store.dispatch('isFirstTimer');
    
  //   if (firstTimer) this.showModal = true;
  // }
};
</script>

<style lang="scss">
.info-modal {
  .el-dialog__title {
    color: $primary !important;
    font-size: 28px!important;
  }
  .el-dialog__body {
    font-size: 16px!important;
      word-break: normal !important;
  }
  .el-dialoag__footer {
    button {
      font-size: 16px!important;
    }
  }
}
</style>
