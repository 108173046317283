import api from '@/utils/services/api';
import jwt_decode from 'jwt-decode';

export default {
  state: {
    userLoggedIn: false,
  },
  mutations: {
    toggleAuth(state) {
      state.userLoggedIn = !state.userLoggedIn;
    },
  },
  actions: {
    isLoggedIn({ commit, dispatch }) {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      const username = localStorage.getItem('username');

      if (accessToken && refreshToken && username) {
        const decodeRefreshToken = jwt_decode(refreshToken);
        const expiration = decodeRefreshToken.exp;
        const now = Date.now() / 1000;

        if (expiration > now) commit('toggleAuth');
        else dispatch('logout');
      }
    },
    logout({ commit }) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('username');
      localStorage.removeItem('user_profile');
      commit('toggleAuth');
    },
    async login({ commit, dispatch }, { username, password }) {
      try {
        const response = await api.post('/user/login', {
          username: username,
          password: password,
        });

        if (response.data.access) {
          localStorage.setItem('access_token', response.data.access);
          localStorage.setItem('refresh_token', response.data.refresh);
          localStorage.setItem('username', username);
          commit('toggleAuth');
          dispatch('getProfile');
          return response;
        }
      } catch (err) {
        return err;
      }
    },
    async register(_, payload) {
      let formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      try {
        const response = await api.post('/user/register', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (response.status === 200) {
          return response;
        }
      } catch (err) {
        return err;
      }
    },
    async refreshToken() {
      try {
        const response = await api.post('/user/token/refresh', {
          refresh_token: localStorage.getItem('refresh_token'),
        });

        if (response.status === 200) {
          localStorage.setItem('access_token', response.data.access);
        }
      } catch (err) {
        return err;
      }
    },
    async getProfile() {
      try {
        const username = localStorage.getItem('username');
        const storageProfile = localStorage.getItem('user_profile');
        const userProfile = JSON.parse(storageProfile);

        if (storageProfile) return userProfile;
        else {
          const response = await api.get(`/user/detail/${username}`);
          if (response.status === 200)
            localStorage.setItem('user_profile', JSON.stringify(response.data));
          return response.data;
        }
      } catch (err) {
        return err;
      }
    },
    async updateProfile(_, payload) {
      try {
        const response = await api.post(
          `/user/update/${payload.username}`,
          payload
        );
        if (response) {
          localStorage.setItem('user_profile', JSON.stringify(payload));
          localStorage.setItem('username', payload.username);
        }
        return response;
      } catch (err) {
        return err;
      }
    },
    async forgotPassword(_, email) {
      let formData = new FormData();
      formData.append('email', email);
      try {
        const response = await api.post('/user/forgot-password', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response;
      } catch (err) {
        return err;
      }
    },
    async resetPassword(_, { password, token }) {
      try {
        const response = await api.post(`/user/reset-password/${token}`, {
          password: password,
        });
        return response;
      } catch (err) {
        return err;
      }
    },
    async updatePassword(_, payload) {
      try {
        const username = localStorage.getItem('username');
        const response = await api.post(`/user/update/${username}`, {
          current_password: payload.current_password,
          password: payload.new_password,
        });
        return response;
      } catch (err) {
        return err;
      }
    },
    async isFirstTimer() {
      const profile = JSON.parse(localStorage.getItem('user_profile'));
      return profile?.first_time;
    },
  },
};
