<template>
  <header class="header shadow">
    <div class="container">
      <nav class="navbar-container">
        <router-link :to="{ name: 'Home' }" class="navbar-logo">
          <img src="@/assets/img/logo.png" class="navbar-brand" alt="CEDaCI Logo"/>
        </router-link>
        <ul class="navbar-menu" :class="{ active: isActive }">
          <li class="navbar-item" @click="isActive = false">
            <router-link :to="{ name: 'Home' }"> Home </router-link>
          </li>
          <li class="navbar-item" @click="isActive = false">
            <a href="https://www.cedaci.org/" target="_blank"> CEDaCI </a>
          </li>
          <li class="navbar-item" v-if="!userLoggedIn" @click="isActive = false">
            <router-link :to="{ name: 'Login' }"> Compass </router-link>
          </li>
          <li class="navbar-item" v-if="userLoggedIn" >
            <el-dropdown>
              <router-link :to="{ name: 'Compass' }"> Compass </router-link>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="isActive = false"
                    ><router-link :to="{ name: 'Compare' }"
                      >Compare</router-link
                    ></el-dropdown-item
                  >
                  <el-dropdown-item @click="isActive = false"
                    ><router-link :to="{ name: 'EndOfLife' }"
                      >End of life</router-link
                    ></el-dropdown-item
                  >
                <el-dropdown-item @click="isActive = false"
                    ><router-link :to="{ name: 'Evaluate' }"
                      >Evaluate</router-link
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </li>
          <li class="navbar-item" v-if="userLoggedIn" @click="isActive = false">
            <router-link :to="{ name: 'Account' }"> My Account </router-link>
          </li>
          <li class="navbar-item" v-if="userLoggedIn" @click="isActive = false">
            <a @click="logout" href="#"> Logout </a>
          </li>
          <li class="navbar-item" v-if="!userLoggedIn" @click="isActive = false">
            <router-link :to="{ name: 'Login' }"> Login </router-link>
          </li>
          <li class="navbar-item" v-if="!userLoggedIn" @click="isActive = false">
            <router-link
              :to="{ name: 'Register' }"
              class="navbar-item__register"
            >
              Register
            </router-link>
          </li>
        </ul>
        <div
          class="hamburger"
          @click="isActive = !isActive"
          :class="{ active: isActive }"
        >
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    userLoggedIn() {
      return this.$store.state.auth.userLoggedIn;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({ name: 'Home' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.navbar-brand {
  width: 3rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 1.25rem;
  height: 3px;
  margin: 0.25rem auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: $dark;
}

.navbar-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  align-items: center;
}

.navbar-item {
  margin-left: 2rem;
  &__register {
    color: $primary !important;
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 10px 40px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $light !important;
      background-color: $primary;
    }
  }
}

@include bp-down(md) {
  .navbar-menu {
    position: fixed;
    right: -100%;
    top: 5rem;
    flex-direction: column;
    background-color: $light;
    width: 100%;
    border-radius: 0.5rem;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    z-index: 1000;
    padding: 1rem 0rem;
  }
  .navbar-menu.active {
    right: 0;
  }
  .navbar-item {
    margin: 1rem 0;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 0.5rem;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }
}
</style>
