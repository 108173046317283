export default [
  {
    src: './partners/LSBU.png',
    alt: 'LSBU',
  },
  {
    src: './partners/WI.jpg',
    alt: 'Wuppertal Institut',
  },
  {
    src: './partners/green-it.png',
    alt: 'Green IT Amsterdam',
  },
  {
    src: './partners/team2.jpg',
    alt: 'Team2',
  },
  {
    src: './partners/weloop.png',
    alt: 'WeLOOP',
  },
  {
    src: './partners/tnd.png',
    alt: 'TND',
  },
  {
    src: './partners/free-ict-europe.jfif',
    alt: 'Free ICT Europe',
  },
  {
    src: './partners/techbuyer.png',
    alt: 'Techbuyer',
  },
  {
    src: './partners/operational-intelligence.png',
    alt: 'Operational Intelligence',
  },
  {
    src: './partners/DialASheep.jpg',
    alt: 'DialASheep',
  },
  {
    src: './partners/aliter-networks.png',
    alt: 'Aliter Networks',
  },
  {
    src: './partners/SIMS.jpg',
    alt: 'SIMS Recycling Solutions',
  },
];
