import api from '@/utils/services/api';

export default {
  state: {},
  mutations: {},
  actions: {
    async getEvaluateData(_, payload) {
      try {
        const response = await api.post('/cdcc/edg', payload);
        return response.data;
      } catch (err) {
        return err;
      }
    },
    async getServerList() {
      try {
        const response = await api.get('/cdcc/product/server');
        return response.data;
      } catch (err) {
        return err;
      }
    },
    async getServerById(_, id) {
      try {
        const response = await api.get(`/cdcc/product/server/${id}`);
        return response.data;
      } catch (err) {
        return err;
      }
    },
    async getComponentByType(_, { type, filter }) {
      let query;

      switch (type) {
        case 'cpu':
        case 'ram':
          query = 'socket';
          break;
        case 'storage':
          query = 'form_factor';
          break;
        default:
          query = '';
      }

      try {
        let response;
        if (query !== '')
          response = await api.get(`/cdcc/product/${type}?${query}=${filter}`);
        else response = await api.get(`/cdcc/product/${type}`);
        return response.data;
      } catch (err) {
        return err;
      }
    },
    async getComponentById(_, payload) {
      try {
        const response = await api.get(
          `/cdcc/product/${payload.type}/${payload.id}`
        );
        return response.data;
      } catch (err) {
        return err;
      }
    },
    async getCompareData(_, rack) {
      try {
        const response = await api.post('/cdcc/assessment/server/full', {
          ...rack,
        });
        return response.data;
      } catch (err) {
        return err;
      }
    },
    async getEolData(_, rack) {
      try {
        const response = await api.post('/cdcc/assessment/server/eol', {
          ...rack,
        });
        
        return response.data;
      } catch (err) {
        return err;
      }
    },
    async exportResults(_, config) {
      try {
        const response = await api.post('/cdcc/export/server', { ...config });
        return response.status;
      } catch (err) {
        return err;
      }
    },
    async getSavedConfig() {
      const username = localStorage.getItem('username');
      const response = await api.get(`/cdcc/${username}/conf/server`);
      if (response.status === 200) return response.data;
    },
    async getSingleConfig(_, label) {
      const username = localStorage.getItem('username');
      const response = await api.get(
        `/cdcc/${username}/conf/server?conf_label=${label}`
      );
      if (response.status === 200) return response.data;
    },
    async saveConfig(_, config) {
      const username = localStorage.getItem('username');
      const response = await api.post(`/cdcc/${username}/conf/server`, config);
      return response.status;
    },
  },
  getters: {},
};
